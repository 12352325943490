<template>
    <div>
        <page-title :heading="$t('settings.lang_navScaleSettings')" :subheading="$t('settings.lang_navScaleSettings')"
                    :icon=icon :show-add-button="true" :permission-add="true"></page-title>
        <div class="app-main__inner">
          <ScaleSettingsComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import ScaleSettingsComponent from "../../../components/settings/moduleSettings/ScaleSettingsComponent";

    export default {
        components: {
          ScaleSettingsComponent,
            PageTitle,
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>